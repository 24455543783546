import ApiService from "@/core/services/api.service";


// action types
export const GET_CATEGORIES = "getCategories";
export const POST_CATEGORIES = "postCategories";
export const PUT_CATEGORIES = "putCategories";

// mutation types
export const SET_CATEGORIES = "setCategories";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    categories: []
};

const getters = {
    categories(state) {
        return state.categories;
    }
};

const actions = {
    [GET_CATEGORIES](context) {
        let url = "Categories?TenantId=1&RetrieveAll=true&AdditionalData.IncludeList=CategoryTranslations";

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                context.commit(SET_CATEGORIES, data.resultList);
                resolve(data.resultList);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [POST_CATEGORIES](context, payload) {

        let url = "Categories";

        return new Promise(resolve => {

            ApiService.post(url, payload).then((response) => {
                if (response)
                    resolve(true);
                else
                    response(false);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CATEGORIES](state, categories) {
        state.errors = {};
        state.categories = categories;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
