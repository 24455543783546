import ApiService from "@/core/services/api.service";
import StartEndDate from "../../../assets/plugins/formvalidation/dist/amd/plugins/StartEndDate";


// action types
export const GET_ITEMS = "getItems";
export const GET_ITEM = "getItem";
export const POST_ITEMS = "postItems";
export const PUT_ITEMS = "putItems";
export const DELETE_ITEMS = "deleteItems";

// mutation types
export const SET_ITEMS = "setItems";
export const SET_ERROR = "setError";
export const REMOVE_ITEM = "removeItem";
export const SET_ITEM = "setItem";

const state = {
    errors: null,
    items: []
};

const getters = {
    items(state) {
        return state.items;
    }
};

const actions = {
    [GET_ITEMS](context, params) {
        let url = "Items?retrieveAll=true&tenantId=1&AdditionalData.IncludeList=ItemTranslations&AdditionalData.IncludeList=StoreItems";

        if (params.searchTerm != null)
            url += `&searchTerm=${params.searchTerm}`;

        if (params.categoryId != null)
            url += `&categoryId=${params.categoryId}`;

        if (params.subcategoryId != null)
            url += `&subcategoryId=${params.subcategoryId}`;

        if (params.groupId != null)
            url += `&groupId=${params.groupId}`;

        if (params.isActive)
            url += `&isActive=${params.isActive}`;

        if (params.isFreeShipping)
            url += `&isFreeShipping=${params.isFreeShipping}`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                context.commit(SET_ITEMS, data.resultList);
                resolve(data.resultList);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [GET_ITEM](context, id) {
        let url = `Items/${id}?IncludeList=ItemTranslations&IncludeList=StoreItems&IncludeList=StoreItems.StoreItemTaxes&IncludeList=Images&IncludeList=ItemAttributes`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [POST_ITEMS](context, payload) {

        let url = "Items";

        console.log(payload);

        return new Promise(resolve => {

            ApiService.post(url, payload).then((response) => {
                if (response.status == 200)
                    resolve(true);
                else
                    resolve(false);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
    [PUT_ITEMS](context, payload) {

        let url = `Items/${payload.id}`;

        return new Promise(resolve => {

            ApiService.put(url, payload).then((response) => {
                if (response.status == 200) {
                    resolve(true);
                    context.commit(SET_ITEM, response.data);
                }
                else {
                    resolve(false);
                }
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
    [DELETE_ITEMS](context, id) {

        let url = `Items/${id}`;

        return new Promise(resolve => {

            ApiService.delete(url).then((response) => {
                if (response.status == 200) {
                    resolve(true);
                    context.commit(REMOVE_ITEM, id);
                }
                else {
                    resolve(false);
                }
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_ITEMS](state, items) {
        state.errors = {};
        state.items = items;
    },
    [SET_ITEM](state, item) {
        state.errors = {};
        var indexOfEditedItem = state.items.map((x) => { return x.id }).indexOf(item.id);
        state.items[indexOfEditedItem].code = item.code;
        state.items[indexOfEditedItem].isActive = item.isActive;
        state.items[indexOfEditedItem].itemTranslations[0].name = item.itemTranslations[0].name;
    },
    [REMOVE_ITEM](state, id) {
        state.error = {};
        var indexOfDeletedItem = state.items.map((x) => { return x.id}).indexOf(id);
        state.items.splice(indexOfDeletedItem, 1)
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
