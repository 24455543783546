// Bosnia and Herzegovina
export const locale = {
  TRANSLATOR: {
    SELECT: "Odaberite Vaš jezik"
  },
  MENU: {
    NEW: "ADD",
    ACTIONS: "Akcije",
    CREATE_POST: "Napišite novu objavu",
    PAGES: "Stranice",
    FEATURES: "Mogućnosti",
    APPS: "Aplikacije",
    DASHBOARD: "Kontrolna ploča",
    SYSTEM_SETTINGS: "Sistemske postavke",
    ITEMS: "Artikli"
  },
  AUTH: {
    GENERAL: {
      OR: "Ili",
      SUBMIT_BUTTON: "Potvrdi",
      NO_ACCOUNT: "Nemate račun?",
      SIGNUP_BUTTON: "Registrujte se",
      FORGOT_BUTTON: "Zaboravljena lozinka",
      BACK_BUTTON: "Nazad",
      PRIVACY: "Privatnost",
      LEGAL: "Legal",
      CONTACT: "Kontakt"
    },
    LOGIN: {
      TITLE: "Prijava na račun",
      BUTTON: "Prijava"
    },
    FORGOT: {
      TITLE: "Zaboravili ste lozinku?",
      DESC: "Unesite Vašu e-mail adresu da resetujete lozinku",
      SUCCESS: "Vaš račun je uspješno resetovan."
    },
    REGISTER: {
      TITLE: "Registracija",
      DESC: "Unesite Vaše podatke da se registrujete",
      SUCCESS: "Vaš račun je uspješno registrovan."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Ime i prezime",
      PASSWORD: "Lozinka",
      CONFIRM_PASSWORD: "Potvrdite lozinku",
      USERNAME: "Korisničko ime"
    },
    VALIDATION: {
      INVALID: "{{name}} nije ispravno",
      REQUIRED: "{{name}} je obavezno",
      MIN_LENGTH: "{{name}} minimalna dužina je {{min}}",
      AGREEMENT_REQUIRED: "Prihvatanje uslova korištenja je obavezno",
      NOT_FOUND: "Traženi{{name}} nije pronađen",
      INVALID_LOGIN: "Podaci za prijavu nisu ispravni",
      REQUIRED_FIELD: "Obavzeno polje",
      MIN_LENGTH_FIELD: "Minimalna dužina polja:",
      MAX_LENGTH_FIELD: "Maksimalna dužina polja:",
      INVALID_FIELD: "Polje nije ispravno"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Označeni broj zapisa ",
      ALL: "Sve",
      SUSPENDED: "Suspendovani",
      ACTIVE: "Aktivni",
      FILTER: "Filter",
      BY_STATUS: "po statusu",
      BY_TYPE: "po tipu",
      BUSINESS: "Poslovni",
      INDIVIDUAL: "Individualnu",
      SEARCH: "Pretraga",
      IN_ALL_FIELDS: "u svim poljima"
    },
    ECOMMERCE: "EmaCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Kupci",
      CUSTOMERS_LIST: "Lista kupaca",
      NEW_CUSTOMER: "Novi kupac",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Brisanje kupca",
        DESCRIPTION: "Jeste li sigurni da želite trajno obrisati kupca?",
        WAIT_DESCRIPTION: "Brisanje kupca...",
        MESSAGE: "Kupac je obrisan"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Brisanje kupaca",
        DESCRIPTION: "Jeste li sigurni da želite trajno obrisati označene kupce?",
        WAIT_DESCRIPTION: "Brisanje kupaca...",
        MESSAGE: "Označeni kupci su obrisani"
      },
      UPDATE_STATUS: {
        TITLE: "Status je ažuriran za označene kupce",
        MESSAGE: "Status označenih kupaca je uspješno ažuriran"
      },
      EDIT: {
        UPDATE_MESSAGE: "Kupac je ažuriran",
        ADD_MESSAGE: "Kupac je kreiran"
      }
    }
    },
    GENERAL: {
        NAME: "Naziv",
        CATEGORY: "Kategorija",
        SUBCATEGORY: "Potkategorija",
        CODE: "Šifra",
        ENTER_NAME_OR_CODE: "Unesite naziv ili šifru...",
        SEARCH: "Traži",
        STATUS: "Status",
        AVAILABILITY: "Dostupnost",
        ADDING: "Dodavanje",
        EDITING: "Izmjena",
        ADD_ITEM: "Dodaj artikal",
        SAVE: "Spremi",
        DESCRIPTION: "Opis",
        PHOTOS: "Slike",
        PRICE: "Cijena",
        STORAGE_STATUS: "Stanje na skladištu",
        CHOOSE_OR_DROP_PHOTOS: "Odaberite ili prevucite slike ovdje",
        CHOOSE_PHOTOS: "Odaberite slike",
        DROP_PHOTOS_HERE: "Prevucite slike ovdje",
        ADD_ITEM: "Dodaj artikal",
        IS_ACTIVE: "Aktivan",
        IS_FREE_SHIPPING: "Besplatna dostava",
        AVAILABLE_QUANTITY: "Dostupna količina",
        RETAIL_PRICE: "Maloprodajna cijena",
        DISCOUNT_PERCENTAGE: "Popust (%)",
        IS_DISCOUNTED: "Vidljivo na popustima",
        CHOOSE_CATEGORY: "Odaberite kategoriju",
        CHOOSE_SUBCATEGORY: "Odaberite potkategoriju",
        ACTIVE: "Aktivan",
        INACTIVE: "Neaktivan",
        CODE_REQ: "Šifra je obavezna",
        NAME_REQ: "Naziv je obavezan",
        CATEGORY_REQ: "Kategorija je obavezna",
        AVAILABLE_QUANTITY_REQ: "Dosupna količina je obavezna",
        RETAIL_PRICE_REQ: "Maloprodajna cijena je obavezna",
        SAVE_AND_CONTINUE: "Spremi i nastavi",
        CLICK_PHOTO_TO_DELETE: "Klikni na sliku za brisanje.",
        NEXT_SHORT: "Sljed",
        PREVIOUS_SHORT: "Pret",
        RESULTS_PER_PAGE: "rezultata po stranici",
        BROWSE: "Odaberi",
        IS_MAIN: "Glavna",
        IS_HOVER: "Zamjenska",
        DELETE: "Izbriši",
        EDIT_ITEM: "Izmjena artikla",
        CANCEL: "Odustani",
        CHOOSE_PHOTO: "Odaberi sliku",
        EDIT: "Izmjena",
        ITEM_ATTRIBUTES: "Atributi artikla",
        IS_FEATURED: "Istaknuto"


    }
};
