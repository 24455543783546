// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language"
  },
  MENU: {
    ADD: "Add",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    SYSTEM_SETTINGS: "System settings",
    ITEMS: "Items"
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact"
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In"
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset."
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered."
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
        USERNAME: "Username"
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields"
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted"
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated"
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created"
      }
    }
    },
    GENERAL: {
        NAME: "Name",
        CATEGORY: "Category",
        SUBCATEGORY: "Subcategory",
        CODE: "Code",
        ENTER_NAME_OR_CODE: "Enter name or code...",
        SEARCH: "Search",
        STATUS: "Status",
        AVAILABILITY: "Availability",
        ADDING: "Adding",
        EDITING: "Editing",
        ADD_ITEM: "Add item",
        SAVE: "Save",
        DESCRIPTION: "Description",
        PHOTOS: "Photos",
        PRICE: "Price",
        IS_ACTIVE: "Is active",
        STORAGE_STATUS: "Storage status",
        CHOOSE_OR_DROP_PHOTOS: "Choose or drag photos here",
        CHOOSE_PHOTOS: "Choose photos",
        DROP_PHOTOS_HERE: "Drop photos here",
        ADD_ITEM: "Add item",
        IS_ACTIVE: "Active",
        IS_FREE_SHIPPING: "Free shipping",
        AVAILABLE_QUANTITY: "Available quantity",
        RETAIL_PRICE: "Retail price",
        DISCOUNT_PERCENTAGE: "Discount (%)",
        IS_DISCOUNTED: "Visible on disocunts",
        CHOOSE_CATEGORY: "Choose category",
        CHOOSE_SUBCATEGORY: "Choose subcategory",
        ACTIVE: "Active",
        INACTIVE: "Inactive",
        CODE_REQ: "Code is required",
        NAME_REQ: "Name is required",
        CATEGORY_REQ: "Category is required",
        AVAILABLE_QUANTITY_REQ: "Available quantity is requierd",
        RETAIL_PRICE_REQ: "Retail price is required",
        SAVE_AND_CONTINUE: "Save and continue",
        CLICK_PHOTO_TO_DELETE: "Click on photo to remove it.",
        NEXT_SHORT: "Next",
        PREVIOUS_SHORT: "Prev",
        RESULTS_PER_PAGE: "results per page",
        BROWSE: "Browse",
        IS_MAIN: "Main",
        IS_HOVER: "Hover",
        DELETE: "Delete",
        EDIT_ITEM: "Edit item",
        CANCEL: "Cancel",
        CHOOSE_PHOTO: "Odaberi sliku",
        EDIT: "Izmjena",
        ITEM_ATTRIBUTES: "Item attributes",
        IS_FEATURED: "Featured"

    }
};
