import ApiService from "@/core/services/api.service";
import StartEndDate from "../../../assets/plugins/formvalidation/dist/amd/plugins/StartEndDate";


// action types
export const GET_ATTRIBUTES = "getAttributes";
export const GET_ITEM = "getAttribute";
export const POST_ATTRIBUTES = "postAttributes";
export const PUT_ATTRIBUTES = "putAttributes";
export const DELETE_ATTRIBUTES = "deleteAttributes";

// mutation types
export const SET_ATTRIBUTES = "setAttributes";
export const SET_ERROR = "setError";
export const REMOVE_ITEM = "removeAttribute";
export const SET_ITEM = "setAttribute";

const state = {
    errors: null,
    attributes: []
};

const getters = {
    attributes(state) {
        return state.attributes;
    }
};

const actions = {
    [GET_ATTRIBUTES](context, params) {
        let url = `Attributes?retrieveAll=true&categoryId=${params.categoryId}&AdditionalData.IncludeList=AttributeTranslations`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                context.commit(SET_ATTRIBUTES, data.resultList);
                resolve(data.resultList);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [GET_ITEM](context, id) {
        let url = `Attributes/${id}?IncludeList=AttributeTranslations&IncludeList=StoreAttributes&IncludeList=StoreAttributes.StoreAttributeTaxes&IncludeList=Images&IncludeList=AttributeAttributes`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                resolve(data);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [POST_ATTRIBUTES](context, payload) {

        let url = "Attributes";

        return new Promise(resolve => {

            ApiService.post(url, payload).then((response) => {
                if (response.status == 200)
                    resolve(true);
                else
                    resolve(false);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
    [PUT_ATTRIBUTES](context, payload) {

        let url = `Attributes/${payload.id}`;

        return new Promise(resolve => {

            ApiService.put(url, payload).then((response) => {
                if (response.status == 200) {
                    resolve(true);
                    context.commit(SET_ITEM, response.data);
                }
                else {
                    resolve(false);
                }
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
    [DELETE_ATTRIBUTES](context, id) {

        let url = `Attributes/${id}`;

        return new Promise(resolve => {

            ApiService.delete(url).then((response) => {
                if (response.status == 200) {
                    resolve(true);
                    context.commit(REMOVE_ITEM, id);
                }
                else {
                    resolve(false);
                }
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
                resolve(false);
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_ATTRIBUTES](state, attributes) {
        state.errors = {};
        state.attributes = attributes;
    },
    [SET_ITEM](state, attribute) {
        state.errors = {};
        var indexOfEditedAttribute = state.attributes.map((x) => { return x.id }).indexOf(attribute.id);
        state.attributes[indexOfEditedAttribute].code = attribute.code;
        state.attributes[indexOfEditedAttribute].isActive = attribute.isActive;
        state.attributes[indexOfEditedAttribute].attributeTranslations[0].name = attribute.attributeTranslations[0].name;
    },
    [REMOVE_ITEM](state, id) {
        state.error = {};
        var indexOfDeletedAttribute = state.attributes.map((x) => { return x.id}).indexOf(id);
        state.attributes.splice(indexOfDeletedAttribute, 1)
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
