import ApiService from "@/core/services/api.service";


// action types
export const GET_SUBCATEGORIES = "getSubategories";
export const POST_SUBCATEGORIES = "POST_SUBCATEGORIES";
export const PUT = "put";

// mutation types
export const SET_SUBCATEGORIES = "setSubcategories";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    subcategories: []
};

const getters = {
    subcategories(state) {
        return state.subcategories;
    }
};

const actions = {
    [GET_SUBCATEGORIES](context, categoryId) {

        let url = `Subcategories?CategoryId=${categoryId}&RetrieveAll=true&AdditionalData.IncludeList=SubcategoryTranslations`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                context.commit(SET_SUBCATEGORIES, data.resultList);
                resolve(data.resultList);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
    [POST_SUBCATEGORIES](context, payload) {

        let url = "Subcategories";

        return new Promise(resolve => {

            ApiService.post(url, payload).then((response) => {
                if (response)
                    resolve(true);
                else
                    response(false);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_SUBCATEGORIES](state, subcategories) {
        state.errors = {};
        state.subcategories = subcategories;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
