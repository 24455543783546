import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import items from "./items.module";
import categories from "./categories.module";
import subcategories from "./subcategories.module";
import attributes from "./attributes.module";
import itemAttributes from "./itemAttributes.module";


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    items,
    categories,
    subcategories,
    attributes,
    itemAttributes
  }
});
