import ApiService from "@/core/services/api.service";
import StartEndDate from "../../../assets/plugins/formvalidation/dist/amd/plugins/StartEndDate";


// action types
export const GET_ITEM_ATTRIBUTES = "getItemAttributes";

// mutation types
export const SET_ITEM_ATTRIBUTES = "setItemAttributes";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    itemAttributes: []
};

const getters = {
    itemAttributes(state) {
        return state.itemAttributes;
    }
};

const actions = {
    [GET_ITEM_ATTRIBUTES](context, params) {
        let url = `ItemAttributes?retrieveAll=true&itemId=${params.itemId}`;

        return new Promise(resolve => {
            ApiService.get(url).then(({ data }) => {
                context.commit(SET_ITEM_ATTRIBUTES, data.resultList);
                resolve(data.resultList);
            }).catch((response) => {
                context.commit(SET_ERROR, response.data.errors);
            });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_ITEM_ATTRIBUTES](state, attributes) {
        state.errors = {};
        state.attributes = attributes;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
